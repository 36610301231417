import { LookupKeys, longMaxAge } from '~/constants';
import { useKookies, useCustomAuth, useSessionId } from '~/composables';

const { orgIdSessionKey } = LookupKeys;

export default defineNuxtRouteMiddleware((to, from) => {
    const { getKookie, setKookie } = useKookies();
    const { getOrGenSessionId } = useSessionId();


    if (import.meta.server) {
        const { sessionObj } = useCustomAuth();
        const current = getKookie(orgIdSessionKey);
        
        if (sessionObj.value) {
            const uid = sessionObj.value.selectedMembershipUid;

            if (uid && current !== uid) {
                setKookie(orgIdSessionKey, uid, { maxAge: longMaxAge });
            }
        }
    }

    if (import.meta.client) {
        getOrGenSessionId();
    }
});
