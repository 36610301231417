<template>
    <v-container>
        <v-row
            v-if="showBanner"
        >
            <v-col>
                <Logo
                    static-size="header"
                    justify="center"
                    height="32"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                class="d-flex flex-column justify-center align-center text-center"
            >
                <span class="text-h1 font-weight-bold">{{ statusCode }}</span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex flex-column justify-center align-center text-center"
            >
                <span class="text-h3">{{ errorMessage }}</span>
            </v-col>
        </v-row>
        <v-row
            class="mt-8"
            dense
        >
            <v-col
                cols="12"
                md="8"
                offset-md="2"
                class="d-flex flex-column justify-center align-center fill-width"
            >
                <div class="mt-4 d-flex flex-column flex-md-row justify-space-around fill-width">
                    <v-btn
                        variant="plain"
                        color="secondary"
                        href="/"
                        @click="clearError({ redirect: '/' })"
                        :block="isMobile"
                    >
                        Back to Home Page
                    </v-btn>
                    <v-btn
                        variant="plain"
                        color="secondary"
                        :href="route.path"
                        @click="clearError()"
                        :block="isMobile"
                    >
                        Reload Page
                    </v-btn>
                    <v-btn
                        variant="plain"
                        color="secondary"
                        href="#"
                        @click.prevent="onGoBack"
                        :block="isMobile"
                    >
                        Go Back
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
    import { useIsMobile } from '~/composables';
    import Logo from '~/components/Logo/Logo.vue';
    import { clearError } from '#app';

    const route = useRoute();
    const { isMobile } = useIsMobile();

    withDefaults(
        defineProps<{
            showBanner?: Nilish<boolean>;
            statusCode?: Nilish<number>;
            errorMessage?: Nilish<string>;
        }>(),
        {
            statusCode: 500,
            errorMessage: ''
        }
    );
    
    async function onGoBack(): Promise<void> {
        return await clearError({ redirect: window.history?.state?.back ?? '/' });
    }
</script>

<style lang="scss"></style>
