import { isString as lo_isString } from 'lodash-es';

const rxFullUrlTest: RegExp = /^(https?:)?\/\//;

export function isUrl(path: unknown): boolean {
    if (!lo_isString(path)) {
        return false;
    }

    return rxFullUrlTest.test(path);
}
