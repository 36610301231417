import { random as lo_random } from 'lodash-es';
import { shuffle as lo_shuffle } from 'lodash-es';
import { toBase64 } from './';

function convertNumToStr(n: number): string {
    return toBase64(Math.abs(n).toString(36)).replace(/[,+\\=_-]/g, '');
}

// Super primitive random ID generator
export const generateId = (length = 32): string => {
    const strArr: string[] = Array(128)
        .fill(0)
        .map(
            () => convertNumToStr(lo_random(0, Number.MAX_SAFE_INTEGER))
        );

    const longStr = lo_shuffle(strArr).join('');
    return longStr.substr(lo_random(0, longStr.length - length), length);
};
