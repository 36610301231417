import { isNumber as lo_isNumber } from 'lodash-es';

export function toStripeTotal(total: number | null | undefined): number | void {
    if (!lo_isNumber(total)) {
        return;
    }

    return Math.round(total * 100);
}

export function fromStripeTotal(total: number | null | undefined): number | void {
    if (!lo_isNumber(total)) {
        return;
    }

    return total / 100;
}
