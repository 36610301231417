import {
    SiteMembershipContext
} from '~/types';
import type {
    ComposableOptionsBase
} from '~/types';
import { LookupKeys } from '~/constants';
import { useKookies, useCustomFetch, useApiUtils } from '~/composables';
import type { NitroFetchOptions } from 'nitropack';
import type { NuxtError } from '#app';

const { orgIdSessionKey } = LookupKeys;

export const SiteMembershipCtx = () => useState<SiteMembershipContext>(() => SiteMembershipContext.Org);


export interface UseOrgsOptions extends ComposableOptionsBase {
    contextPrefix?: string;
    initialContext?: Nullable<SiteMembershipContext>;
}
export function useOrgs(options: Partial<UseOrgsOptions> = {}) {
    const apiBasePath = `/v1`;
    const { useReactiveKookie } = useKookies();
    const siteCurrentOrgUid = useReactiveKookie<Nilish<string>>(orgIdSessionKey);
    const siteMembershipCtx = SiteMembershipCtx();
    const useApiUtilsObj = useApiUtils();
    const $_fetch = useCustomFetch();

    // Set initial context
    if (options.initialContext) {
        siteMembershipCtx.value = options.initialContext;
    }
    
    function toggleSiteMembershipCtx(membershipCtx?: SiteMembershipContext) {
        siteMembershipCtx.value = membershipCtx ?? (
            siteMembershipCtx.value === SiteMembershipContext.Personal ?
            SiteMembershipContext.Org : SiteMembershipContext.Personal
        );
    }

    function setCurrentOrgUid(uid: string = ''): string {
        siteCurrentOrgUid.value = uid || null;
        return uid;
    }

    async function createOnboardingRequest<T extends object>(data: T): Promise<T | NuxtError> {
        const url = `${apiBasePath}/teams/onboarding-requests/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: data
        };

        try {
            return await $_fetch<T>(url, config);
        } catch (err) {
            return useApiUtilsObj.getNuxtErrorObj(err);
        }
    }

    return {
        siteMembershipCtx: readonly(siteMembershipCtx),
        siteCurrentOrgUid: readonly(siteCurrentOrgUid),
        toggleSiteMembershipCtx,
        setCurrentOrgUid,
        createOnboardingRequest
    };
}
