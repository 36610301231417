import type { H3Event } from 'h3';
import { parse, serialize, type CookieSerializeOptions } from 'cookie-es';
import type { CookieOptions, CookieRef } from '#app';
import { useJSON } from '~/composables';
import { isNil as lo_isNil } from 'lodash-es';


export function useKookies() {
    const ctx = useNuxtApp();
    const $config = useRuntimeConfig();
    const { encode, decode } = useJSON();
    const { storagePrefix, defaultCookieOptions } = $config.public;
    
    function setKookie<V extends any>(key: string, value: V, options: CookieSerializeOptions = {}, prefix?: Nilish<string>): void {
        const prefixedKey = getKookiePrefixedName(key, prefix);
        options = { ...defaultCookieOptions, ...options };
        return setKookieByFullName(prefixedKey, value, options);
    }

    function setKookieByFullName<V extends any>(key: string, value: V, options: CookieSerializeOptions = {}, prefix?: Nilish<string>): void {
        const newVal = encode(value);
        options = { ...defaultCookieOptions, ...options };
        
        // Unset null, undefined
        if (lo_isNil(value)) {
            options.maxAge = -1;
        }

        try {
            const cookieString = serialize(key, newVal, options);

            if (import.meta.client) {
                document.cookie = cookieString;
            } else if (import.meta.server && ctx.ssrContext?.event.node.res) {
                setH3Cookie(ctx.ssrContext.event, cookieString);
            }
        } catch (err) {
            console.info('Error setting cookie', err);
        }
    }

    function getKookies(): Record<string, any> | void {
        const cookieStr = import.meta.client ? document.cookie : ctx.ssrContext!.event.node.req.headers.cookie;
        return parse(cookieStr as string || '') || {}
    }

    function getKookie(key: string, prefix?: Nilish<string>): Nilish<string> {
        const prefixedKey = getKookiePrefixedName(key, prefix);
        return getKookieByFullName(prefixedKey);
    }

    function getKookieByFullName(key: string): Nilish<string> {
        const cookies = getKookies();
        return decode(cookies![key] ? decodeURIComponent(cookies![key] as string) : undefined)
    }

    function removeKookie(key: string, options: CookieSerializeOptions = {}, prefix?: Nilish<string>): void {
        const defaultOpts = { expires: new Date(0) };
        setKookie(key, undefined, { ...defaultOpts, ...options }, prefix);
    }

    function useReactiveKookie<T = Nilish<string>>(key: string, options: CookieOptions<T> & { readonly?: false | undefined; } = {}, prefix?: Nilish<string>): CookieRef<T> {
        const prefixedKey = getKookiePrefixedName(key, prefix);
        return useCookie<T>(prefixedKey, options);
    }

    function setH3Cookie(event: H3Event, serializedCookie: string) {
        // Send Set-Cookie header from server side
        let cookies = (event.node.res.getHeader('Set-Cookie') as string[]) || [];

        if (!Array.isArray(cookies)) {
            cookies = [cookies];
        }
        
        cookies.unshift(serializedCookie);

        if (!event.node.res.headersSent) {
            event.node.res.setHeader('Set-Cookie', cookies.filter(
                (value, index, items) => items.findIndex(
                    (val) => val.startsWith(value.slice(0, value.indexOf('=')))
                ) === index
            ));
        }
    }

    function getKookiePrefixedName(name: string, prefixOverride?: Nilish<string>): string {
        return `${prefixOverride ?? storagePrefix}${name}`;
    }

    return {
        setKookie,
        setKookieByFullName,
        getKookie,
        getKookies,
        getKookieByFullName,
        removeKookie,
        useReactiveKookie,
        getKookiePrefixedName
    };
}
