import type {
    GenericApiResponse,
    LoginResponse,
    AuthTokenResponse,
    UserProfile,
    Login
} from '~/types';
import type { NitroFetchOptions } from 'nitropack';
import { isError as lo_isError } from 'lodash-es';
import { useAuthUtils, useDebug } from '~/composables';
import type { RouteLocationRaw } from 'vue-router';
import { pathToRouteObj } from '~/utils';

const defaultRedirectPath = '/';

const IsLoggedIn = () => useState<boolean>(
    'keyAuthIsLoggedIn',
    () => false
);

const RedirectNext = () => useState<string>(
    'keyAuthSignInRedirectNext',
    () => defaultRedirectPath
);

const UserProfileObj = () => useState<Nullable<UserProfile>>(
    'keyAuthUserProfileObj',
    () => null
);

const TokenAccess = () => useState<Nullable<string>>(
    'keyAuthTokenAccess',
    () => null
);

const TokenAccessExp = () => useState<Nullable<number>>(
    'keyAuthTokenAccessExp',
    () => null
);


export function useCustomAuth() {
    const redirectNext = RedirectNext();
    const userProfileObj = UserProfileObj();
    const tokenAccess = TokenAccess();
    const tokenAccessExp = TokenAccessExp();
    const isLoggedIn = IsLoggedIn();
    const { getLocalAuthEndpoint } = useAuthUtils();
    // const debugConsole = useDebug();

    const sessionObj = computed<Nullable<UserProfile>>(() => userProfileObj.value);

    async function signIn(payload: Login, args?: Record<string, any>): Promise<LoginResponse | Error> {
        try {
            const config: NitroFetchOptions<string> = {
                method: 'POST',
                body: payload
            };
            const url = getLocalAuthEndpoint('login');
            const response = await $fetch<LoginResponse>(url, config);

            if (lo_isError(response) || !response?.user) {
                reset();
                throw response;
            }

            const { user, token } = response;
            
            setSession(user);
            setToken(token.access, token.accessExp);
            setLoggedIn(true);

            return response;
        } catch (err) {
            throw err;
        }
    }

    async function signOut(): Promise<void> {
        try {
            const url = getLocalAuthEndpoint('logout');
            await $fetch<GenericApiResponse>(url, { method: 'POST' });
        } catch (err) {}

        reset();
    }

    async function getSession(force = false): Promise<Nullable<UserProfile>> {
        if (!force && userProfileObj.value) {
            return userProfileObj.value;
        }

        const config: NitroFetchOptions<string> = {
            method: 'GET'
        };

        if (tokenAccess.value) {
            config.headers = {
                Authorization: `Bearer ${tokenAccess.value}`
            };
        }

        try {
            const url = getLocalAuthEndpoint('user');
            const response = await $fetch<UserProfile>(url, config);

            if (lo_isError(response)) {
                throw response;
            }

            userProfileObj.value = response;
            setLoggedIn(true);
            return response;
        } catch (err) {
            throw err;
        }
    }

    function setSession(userObj?: UserProfile): void {
        userProfileObj.value = userObj ?? null;
        setLoggedIn(!!userObj);
    }

    async function refreshTokens(refreshToken?: string): Promise<boolean> {
        try {
            const url = getLocalAuthEndpoint('refresh');
            const response = await $fetch<AuthTokenResponse>(url, {
                method: 'POST',
                body: { refresh: refreshToken || null }
            });
            
            if (lo_isError(response) || !response?.token) {
                console.info('REFRESH: ERROR!');
                reset();
                return false;
            }

            const { access, accessExp } = response.token;
            setToken(access, accessExp);
        } catch (err) {
            return false;
        }
        
        setLoggedIn(true);
        return true;
    }

    function getToken(): Nullable<string> {
        // debugConsole.info('getToken: ', tokenAccess.value);
        return tokenAccess.value ?? null;
    }

    function setToken(token?: string, exp?: number): void {
        // debugConsole.info('setToken: ', token);
        tokenAccess.value = token ?? null;
        tokenAccessExp.value = exp ?? null;
    }

    function isTokenExpired(): boolean {
        if (!tokenAccess.value || !tokenAccessExp.value) {
            return true;
        }
        
        const exp = (new Date(tokenAccessExp.value)).getTime();

        if (isNaN(exp)) {
            return true;
        }

        return Date.now() >= exp;
    }

    function setLoggedIn(state: boolean) {
        // debugConsole.info('setLoggedIn: ', state);
        isLoggedIn.value = state;
    }

    function reset(): void {
        setToken();
        setSession();
        setLoggedIn(false);
    }

    function getRedirect(): string | Partial<RouteLocationRaw> {
        const path = decodeURIComponent(redirectNext.value ?? defaultRedirectPath);
        return pathToRouteObj(path) || defaultRedirectPath;
    }

    function setRedirect(path: string): void {
        redirectNext.value = encodeURIComponent(path);
    }

    function clearRedirect(): void {
        redirectNext.value = defaultRedirectPath;
    }

    return {
        signIn,
        signOut,
        isLoggedIn: readonly(isLoggedIn),
        tokenAccess: readonly(tokenAccess),
        setLoggedIn,
        sessionObj,
        getRedirect,
        setRedirect,
        clearRedirect,
        getSession,
        setSession,
        refreshTokens,
        isTokenExpired,
        getToken,
        setToken,
        reset
    };
}
