import { get as lo_get } from 'lodash-es';
import { trimEnd as lo_trimEnd } from 'lodash-es';
import { camelCase as lo_camelCase } from 'lodash-es';
import { pathJoin } from '~/utils';

export function useCdnResource() {
    const $config = useRuntimeConfig();
    const getBaseUrl = (name: string) =>
        lo_trimEnd(lo_get($config, ['public', 'staticAssets', 'cdn', lo_camelCase(name), 'baseUrl'], ''), '/');
    const cdnBasePathMain = getBaseUrl('main');
    const cdnBasePathImages = getBaseUrl('images');
    const cdnBasePathCmsStatic = getBaseUrl('cmsStatic');
    const cdnBasePathSkCms = getBaseUrl('skCms');

    const cdnLookup = {
        main: cdnBasePathMain,
        images: cdnBasePathImages,
        'cms-static': cdnBasePathCmsStatic,
        'sk-cms': cdnBasePathSkCms
    } as const;

    function getResourceCdnUrl(path: string | unknown, cdn: string = 'main'): string {
        return pathJoin(cdnLookup[cdn as keyof typeof cdnLookup], String(path));
    }

    return {
        cdnBasePathMain,
        cdnBasePathImages,
        cdnBasePathCmsStatic,
        cdnBasePathSkCms,
        getResourceCdnUrl
    };
}
