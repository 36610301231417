import type {
    StripeShippingAddressElementOptions,
    PaymentRequestShippingAddress,
    PaymentRequestItem,
    PaymentRequestShippingOption,
    ContactOption,
    StripeError
} from '@stripe/stripe-js';
import type {
    Address,
    StripeShippingObject,
    CartLine,
    Shipments,
    ShipmentRate
} from '~/types';
import { isNil as lo_isNil } from 'lodash-es';
import { isObject as lo_isObject } from 'lodash-es';
import { toStripeTotal } from '~/utils';
import {
    useAddressesUtils
} from '~/composables';

export function useStripeUtils() {

    const { getFirstLastName } = useAddressesUtils();

    function toStripeAddress(addressObj: Address): ContactOption;
    function toStripeAddress(addressObj: Address): StripeShippingObject {
        return {
            name: `${addressObj.firstName} ${addressObj.lastName}`,
            address: {
                line1: addressObj.street1,
                line2: addressObj.street2,
                city: addressObj.city,
                state: addressObj.state,
                postal_code: addressObj.postalCode,
                country: addressObj.country || 'US'
            }
        };
    }

    function convertStripeAddress(stripeShippingObj: StripeShippingObject, extObj: Partial<Address> = {}): Nilish<Address> {
        if (!stripeShippingObj || !stripeShippingObj.address) {
            return;
        }

        const address = stripeShippingObj.address;

        return {
            ...getFirstLastName(stripeShippingObj?.name),
            street1: address.line1 ?? null,
            street2: address.line2 ?? null,
            city: address.city!,
            state: address.state!,
            postalCode: address.postal_code!,
            country: address.country!,
            isShipping: true,
            isBilling: false,
            ...extObj
        };
    }

    function convertPaymentReqAddress(paymentReqAddress: PaymentRequestShippingAddress, extObj: Partial<Address> = {}): Address | void {
        if (!paymentReqAddress) {
            return;
        }

        return {
            ...getFirstLastName(paymentReqAddress?.recipient),
            street1: paymentReqAddress.addressLine?.[0] ?? null,
            street2: paymentReqAddress.addressLine?.[1] ?? null,
            city: paymentReqAddress.city!,
            state: paymentReqAddress.region!,
            postalCode: paymentReqAddress.postalCode!,
            country: paymentReqAddress.country!,
            isShipping: false,
            isBilling: false,
            ...extObj
        };
    }

    function cartLinesToPaymentItem(cartLine: CartLine): PaymentRequestItem {
        const itemName = cartLine?.sku?.name ?? 'Item';
        const label = `${itemName} (x${cartLine.qty})`;

        return {
            label,
            amount: toStripeTotal(cartLine.total)!
        };
    }

    function cartLinesToPaymentItems(cartLines: CartLine[]): PaymentRequestItem[] | void {
        if (Array.isArray(cartLines)) {
            return cartLines.map((cartLine) => cartLinesToPaymentItem(cartLine));
        }
    }

    function shipmentToPaymentReqShipOpts(shipments: Shipments): PaymentRequestShippingOption[] | Error | void {
        if (Array.isArray(shipments)) {
            if (shipments.length > 1) {
                throw new Error('Split shipment not supported');
            }

            return shipments[0].estimate.rates.map((rate) => ({
                id: rate.uid!,
                label: rate.service,
                detail: rate.description,
                amount: toStripeTotal(rate.total)!
            }));
        }
    }

    function getStripeErrorMsg(err: StripeError, baseMsg: string): string {
        let msg = baseMsg;
        const id = err.payment_intent?.id;

        switch (err.type) {
            case 'invalid_request_error':
            case 'authentication_error':
            case 'card_error':
                if (err.message) {
                    msg = err.message;
                    break;
                }
            case 'api_connection_error':
            case 'api_error':
            case 'idempotency_error':
            case 'rate_limit_error':
            default:
                msg = baseMsg;
        }

        if (id) {
            msg = `${msg} Reference ID: ${id}.`;
        }

        return msg;
    }

    function isStripeError(obj: unknown): boolean {
        let result = false;

        if (lo_isObject(obj)) {
            result = !lo_isNil((obj as StripeError)?.type);
        }

        return result
    }

    return {
        getFirstLastName,
        toStripeAddress,
        convertStripeAddress,
        convertPaymentReqAddress,
        cartLinesToPaymentItems,
        shipmentToPaymentReqShipOpts,
        getStripeErrorMsg,
        isStripeError
    };
}
