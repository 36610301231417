import type { TokenCookieNames } from '~/types';
import type { RuntimeConfig } from 'nuxt/schema';
import { useRuntimeConfig } from '#imports';
import { pathJoin } from '~/utils';

const localAuthBasePath = '/api-local/auth';

export function useAuthUtils() {
    const $config = useRuntimeConfig();
    const { storagePrefix } = $config.public;
    const { apiBaseURL } = $config.public;

    const cookieNames: TokenCookieNames = {
        access: `${storagePrefix}_access`,
        accessExp: `${storagePrefix}_access_exp`,
        refresh: `${storagePrefix}_refresh`,
        refreshExp: `${storagePrefix}_refresh_exp`
    };

    const cookieConfig: Nullable<RuntimeConfig['public']['auth']['cookie']> = $config.public?.auth?.cookie ?? null;
    
    function getServerAuthEndpoint(path: string) {
        return pathJoin(apiBaseURL, path);
    }

    function getLocalAuthEndpoint(path: string) {
        return pathJoin(localAuthBasePath, path);
    }

    return {
        cookieNames,
        cookieConfig,
        getServerAuthEndpoint,
        getLocalAuthEndpoint
    };
}
