import type { PublicRuntimeConfig } from 'nuxt/schema';
import type {
    Membership,
    SiteMembershipContext,
    CustomVListItemProps
} from './';
import type { RouteLocationRaw } from '#vue-router';


export type AccountNavItemPermissions = PartialRecord<
    SiteMembershipContext, string[]
>;

export interface AccountNavItem {
    id: string;
    title: string;
    value: number;
    isSubHeader?: boolean;
    hasAccess?: boolean;
    hideInMenu?: boolean;
    featureFlagKey?: keyof PublicRuntimeConfig['featureFlags'];
    permissions?: Nilable<AccountNavItemPermissions>;
    to?: RouteLocationRaw;
}

export interface AccountNavGroupItem {
    title: string;
    hideInMenu?: boolean;
    hideTitleInMenu?: boolean;
    items: AccountNavItem[];
}

export type AccountNav = Record<string, AccountNavGroupItem>;


export interface Address {
    uid?: string;
    label?: Nullable<string>;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    company?: Nullable<string>;
    street1: Nullable<string>;
    street2?: Nullable<string>;
    street3?: Nullable<string>;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone?: Nullable<string>;
    email?: Nullable<string>;
    latitude?: Nullable<string>;
    longitude?: Nullable<string>;
    timezone?: Nullable<string>;
    isComplete?: boolean;
    isResidential?: Nullable<boolean>;
    isValid?: boolean;
    isHidden?: boolean;
    isEditable?: boolean;
    canonical?: string;
    lines?: string[];
    isShipping: boolean;
    isBilling: boolean;
}

export interface DefaultAddresses {
    defaultShippingAddress: Address;
    defaultBillingAddress: Address;
}

export interface UserProfile extends Partial<DefaultAddresses> {
    uid?: string;
    email: string;
    avatarUrl: string;
    label: string;
    firstName: string;
    lastName: string;
    phone: Nullable<string>;
    gender: Nullable<string>;
    giftCardBalance: number;
    customerSince: string;
    hasBodies: boolean;
    dateOfBirth?: Nullable<string>;
    defaultShippingAddressUid?: Nullable<string>;
    defaultBillingAddressUid?: Nullable<string>;
    stripeCustomerId: string;
    accountCreditBalance: number;
    // Membership specific
    selectedMembershipUid: string;
    availableMembershipsSiteData: Membership[];
}

export interface FetchUserResponse {
    user: UserProfile;
}

export type UserProfileUpdateResponse = FetchUserResponse;

export interface UserSignUpRequest {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    gender: Nullable<string>;
    token?: Nullable<string>;
    newsletter?: boolean;
}

export type DefaultAddressType = 'shipping' | 'billing';

// Bodies

export type UserGender = 'M' | 'F';

export enum UserBodyMale {
    SLIM = 1,
    AVERAGE = 2,
    ATHLETIC = 3,
    HUSKY = 4
}

// Ethnicity
export enum EthnicityInputValueKeys {
    None = 'None',
    Asian = 'Asian',
    Black = 'Black',
    Hispanic = 'Hispanic',
    White = 'White',
    'Native American' = 'Native American',
    Unknown = 'Unknown'
}

// NOTE: This extends the Vuetify list item interface and adds as "props". I feel like the .props is a bug on their end but it works.
// Those props should be on the root of the interface.
export interface EthnicityListItem extends CustomVListItemProps {
    text: string;
    ethnicity: EthnicityInputValueKeys;
    props?: CustomVListItemProps;
}

export interface EthnicityListItemValue {
    ethnicity: Nullable<EthnicityInputValueKeys>;
    split: number;
    props?: CustomVListItemProps;
}

export type EthnicityInputValue = {
    [key in EthnicityInputValueKeys]: number;
};

export interface BodyTypeListItem {
    text: string;
    bodyType: Nullable<UserBodyMale>;
    imageUrl?: string;
}

export interface Person {
    pk?: string;
    uid?: string;
    userPk: string;
    user: UserProfile;
}

export interface UserBody {
    uid?: string;
    isSelf: boolean;
    name: string;
    personPk: string;
    person: Person;
    email: Nullable<string>;
    birthdate: string;
    age: Nullable<number>;

    /**
    *  @deprecated
    */
    height: number;
    /**
    *  @deprecated
    */
    weight: number;

    heightCm: number;
    heightIn: Nullable<number>;
    weightKg: number;
    weightLb: Nullable<number>;
    heightFmt: string;
    heightMetricFmt: string;
    weightFmt: string;
    weightMetricFmt: string;
    maleBodyType1: Nullable<UserBodyMale>;
    gender: Nullable<UserGender>;
    braSize: Nullable<string>;
    created: Nullable<string>;
    updated: Nullable<string>;
    ethnicity?: Nullable<EthnicityInputValue>;
    


    isArchived?: boolean;
    p1Id?: number;
    p1PersonId?: number;
    shouldCreatePerson?: boolean;
}

// Warranty Claims
export interface WarrantyClaim {
    pk?: string;
    uid?: string;
    created?: string;
    status: string;
    credit: string;
    sku: string;
    proofOfPurchase: string;
}

export interface ParamsSetAddressDefaults {
    pk?: string;
    uid: string;
    asShipping: boolean;
    asBilling: boolean;
}

export interface FirstLastName {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
}


export enum AddressesEndpointType {
    ADDRESSES = 'addresses',
    MEM_BILLING = 'membershipBilling',
    MEM_SHIPPING = 'membershipShipping'
}

export interface NavAddress {
    name: string;
    contact: string;
    address: string;
    address2: Nilable<string>;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: Nilable<string>;
    email: Nilable<string>;
}
