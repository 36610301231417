import type { Address, UserProfile } from './';

export interface OrgRole {
    uid: string;
    name: string;
    isOrg: boolean;
    isTeam: boolean;
}

export interface OrgSignUpContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    gender: Nilish<string>;
    role: string;
}

export interface OrgTeamSignUpRequest {
    teamName: string;
    sport: string;
    level: string;
    teamGender: string;
    city: string;
    state: string;
    country: string;
    website: Nilish<string>;
    logo?: Nilish<string>;
    message: string;
    contacts: OrgSignUpContact[];
}

















/**
*  @deprecated
*/
export interface OrgsRetailersTeamsSignUpRequest {
    type: string;
    businessName: string;
    markets: string[];
    billingAddress: Nullable<Address>;
    shippingAddress: Nullable<Address>;
    shippingAccountNumber: Nullable<string>;
    shippingAccountCarrier: Nullable<string>;

    yearEstablished?: number;
    website?: string;
    instagramSocialHandle?: string;
    facebookSocialHandle?: string;
    twitterSocialHandle?: string;
    youtubeSocialHandle?: string;

    logo?: string;
    physicalStore?: boolean;
    onlineStore?: boolean;
    sport?: string;
    gender?: string;
    level?: string;
    contacts?: Partial<UserProfile>[];
    contactRoles?: string[];
    resellerCertificate?: File[];
    resellerCertificateState?: string[];
}

export interface OrgType {
    id: number;
    name: string;
    isSchool: boolean;
}

export interface TeamsSport {
    id: number;
    sortOrder: number;
    name: string;
    shortCode: string;
    slug: string;
}

export interface TeamsLevel {
    id: number;
    name: string;
    isProfessional: boolean;
    shortCode: string;
}

export interface TeamsRole {
    id: number;
    name: string;
    namePlural: string;
    level: string;
}

export interface TeamsMarket {
    uid: string;
    name: string;
    shortCode: string;
    contactEmail: string;
    titleDescription: string;
    metaDescription: string;
}

export interface TeamsPositionSport {
    market: TeamsMarket;
    name: string;
    shortCode: string;
    contactEmail: string;
    nameWithoutGender: string;
}

export interface TeamsPosition {
    id: number;
    name: string;
    sport: TeamsPositionSport;
}

export enum SiteMembershipContext {
    Org = 'org',
    Personal = 'personal'
}
