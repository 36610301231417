import { isString as lo_isString } from 'lodash-es';

export function useJSON() {
    
    function encode(val: any): string {
        if (lo_isString(val)) {
            return val;
        }
        
        return JSON.stringify(val);
    }

    function decode(val: any): any {
        if (lo_isString(val)) {
            try {
                return JSON.parse(val);
            } catch (_) {}
        }

        return val;
    }

    return {
        encode,
        decode
    };
}
