import { isUndefined as lo_isUndefined } from 'lodash-es';
import { useIsMobile } from './';
import type { GetViewportHeightCssOptions, GetViewportHeightCss, UiStateConstants } from '~/types';

export const uiStateConstants: UiStateConstants = {
    headerHeightDesktopPx: 56,
    headerHeightMobilePx: 48,
    globalPageBannerDesktopPx: 30,
    globalPageBannerMobilePx: 50
}

export const canUseNewVpUnits = import.meta.client && CSS?.supports('height', '1svh');

export function getMobileHeightValue(unit: string = 'svh', value: number = 100) {
    const fallback = `${value}vh`;
    return import.meta.client ? (canUseNewVpUnits ? `100${unit}` : fallback) : fallback;
}

export function useUiState() {
    const { isMobile } = useIsMobile();
    const {
        headerHeightMobilePx,
        headerHeightDesktopPx,
        globalPageBannerDesktopPx,
        globalPageBannerMobilePx
    } = uiStateConstants;

    const headerHeightPx = computed(() => getHeaderHeightPx(isMobile.value));

    function getHeaderHeightPx(hasMobile: boolean, hasBanner: boolean = false): number {
        const h = hasMobile ? headerHeightMobilePx : headerHeightDesktopPx;
        const b = hasBanner ? (hasMobile ? globalPageBannerMobilePx : globalPageBannerDesktopPx) : 0;
        return h + b;
    }

    function getViewportHeightCss(options?: GetViewportHeightCssOptions): GetViewportHeightCss {
        const { desktopValue, rawValues = false } = options ?? {};
        const baseVal = !isMobile.value && !lo_isUndefined(desktopValue) ? desktopValue : getMobileHeightValue();
        const top: number | null = headerHeightPx.value ?? 0;

        if (import.meta.client) {
            return rawValues ? { baseVal, top } : `calc(${baseVal} - ${top}px)`;
        }

        return baseVal;
    }

    function setRootCssVar(property: string, value: string | null, priority?: string | undefined): void {
        if (import.meta.client) {
            document.documentElement.style.setProperty(property, value, priority);
        }
    }

    return {
        uiStateConstants,
        getHeaderHeightPx,
        headerHeightPx,
        getViewportHeightCss,
        canUseNewVpUnits,
        getMobileHeightValue,
        setRootCssVar
    };
}
