import revive_payload_client_yFafl49hy0 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iJEKcu57zM from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q05fi0glEo from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yugN5t1Vc3 from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.1_vite@5.4.2_@types+node@22.5.0_sass@1.77._25quqmlem6ll4jrqv2o7kubf54/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cKJ6qZtyIr from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_H3WeCJo1rB from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0eXZb9pyqo from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_YZmJMw3I4D from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_mgyi5Bhbdt from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_4BVIWhdHt9 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_efk2oqihgokwdm7b7a6pdy7jw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_yFafl49hy0,
  unhead_iJEKcu57zM,
  router_Q05fi0glEo,
  _0_siteConfig_yugN5t1Vc3,
  payload_client_cKJ6qZtyIr,
  navigation_repaint_client_H3WeCJo1rB,
  check_outdated_build_client_0eXZb9pyqo,
  restore_state_client_YZmJMw3I4D,
  chunk_reload_client_mgyi5Bhbdt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4BVIWhdHt9,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]