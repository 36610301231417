import {
    parse as parseDate,
    format as dtFormat,
    date as dateFn
} from '@formkit/tempo';
import { isString as lo_isString } from 'lodash-es';
import { isDateValid } from '.';


export const stdDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MM/DD/YYYY';
export const fullAbvDateFormat = 'ddd MMM D YYYY';

// TODO: Move to date-helpers
// TODO: Rename: maybe `nativeDtParse`
export function parseDt(dt: string | Date): Date {
    if (!lo_isString(dt)) {
        return dt;
    }

    return new Date(Date.parse(dt));
}

export function fmtDate(dt: string | Date, fmtStr: string, ts?: Nullable<string>): string {
    ts = ts ? ` ${ts}` : '';
    return dtFormat(parseDt(`${dt}${ts}`), fmtStr);
}

// TODO: Move to date-helpers
export function parseSimpleDate(dt: string): Date | null {
    try {
        const [exYr, exMo, exDy] = String(dt)
            .split('-')
            .map((s) => {
                const ds = parseInt(s, 10);

                if (!isNaN(ds)) {
                    return ds;
                }

                throw new Error('NaN');
            });

        const d = new Date(exYr, exMo, exDy);

        if (isDateValid(d)) {
            return d;
        }
    } catch (err) {}

    return null;
}

export function fmtISODate(dt: string, fmtStr: string): string {
    return dtFormat(dateFn(dt), fmtStr);
}

export function fmtShipDate(dt: string, includeYear = false, ts?: Nullable<string>): string {
    const fmt = 'ddd M/D' + (includeYear ? '/YYYY' : '');
    return fmtDate(dt, fmt, ts);
}

export function fmtShipTime(tm: string, ts?: Nullable<string>): string {
    return fmtDate(`1/1/2000 ${tm}`, 'h:mm A', ts);
}


export function dateSafeFormat(dt: string, inputFormat: string, outputFormat: string = stdDateFormat): Nullable<string> {
    let output: Nullable<string> = null;

    try {
        const newDate = parseDate({
            date: dt,
            format: inputFormat,
            locale: 'en'
        });

        if (newDate.toString() !== 'Invalid Date') {
            output = dtFormat(newDate, outputFormat);
        }
    } catch(error) {}

    return output;
}

