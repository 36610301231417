import { castArray as lo_castArray } from 'lodash-es';
import { compact as lo_compact } from 'lodash-es';
import { uniq as lo_uniq } from 'lodash-es';
import { useKookies } from '~/composables';
import { longMaxAge } from '~/constants';


const maxLenTrackers = 25;
let cachedTrackers: Nullable<string[]> = null;

export function useTrackingUtils() {
    const trackerStoreKey = 'tracker';
    const { setKookie, getKookie, removeKookie } = useKookies();
    
    function appendTrackingPixel(url: string): void {
        try {
            const img = new Image();
            img.src = url;
            document.body.appendChild(img);
        } catch (error) {
            console.info('Error appending tracking pixel: ', error, url);
        }
    }

    function setTrackerId(value: string | string[]): void {
        let trackers = deserializeTrackerCookie();
        value = lo_castArray(value);
        
        if (!Array.isArray(trackers)) {
            return;
        }

        value.forEach((val: string) => {
            trackers.push(val.trim());
        });

        trackers = sanitizeTrackers(trackers);
        cachedTrackers = trackers;
        serializeTrackerCookie(trackers);
    }

    function getTrackers(): string[] {
        if (cachedTrackers?.length) {
            return cachedTrackers;
        }

        let trackers = deserializeTrackerCookie();

        if (!Array.isArray(trackers)) {
            return [];
        }

        return sanitizeTrackers(trackers);
    }

    function removeTrackers(ids?: Nilish<string[]>): void {
        // Clears all
        if (!ids) {
            cachedTrackers = null;
            serializeTrackerCookie([]);
            return;
        }

        let trackers = deserializeTrackerCookie();

        if (!Array.isArray(trackers)) {
            return;
        }

        const filtered = trackers.filter((id) => !ids.includes(id));
        trackers = sanitizeTrackers(filtered);
        cachedTrackers = trackers;
        serializeTrackerCookie(trackers);
    }

    function deserializeTrackerCookie(): string[] {
        const trackerStr = getKookie(trackerStoreKey) ?? '';
        return lo_compact(String(trackerStr).split(','));
    }
    
    function serializeTrackerCookie(trackers: string[]): void {
        const arrStr = trackers.join(',');

        // Remove empty cookie
        if (!arrStr.length) {
            removeKookie(trackerStoreKey);
            return;
        }

        setKookie(trackerStoreKey, arrStr, { maxAge: longMaxAge });
    }

    function sanitizeTrackers(trackers: string[]): string[] {
        return lo_uniq(trackers).slice(-maxLenTrackers);
    }

    return {
        appendTrackingPixel,
        setTrackerId,
        getTrackers,
        removeTrackers
    };
}
