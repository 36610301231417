import type {
    GenericApiErrorResponse,
    CustomPagination,
    PaginatedPayload,
    ApiGeneralException
} from '~/types';
import { HttpStatusCodeType } from '~/types';
import type { FetchError } from 'ofetch';
import { isError as lo_isError } from 'lodash-es';
import { has as lo_has } from 'lodash-es';
import { omit as lo_omit } from 'lodash-es';
import { isObject as lo_isObject } from 'lodash-es';
import { capitalize as lo_capitalize } from 'lodash-es';
import { inRange as lo_inRange } from 'lodash-es';
import { $URL } from 'ufo';
import type { NuxtError } from '#app';
import type { RuntimeConfig } from 'nuxt/schema';

const strUnknownError = 'unknown error';
const genericErrorProps: Array<keyof GenericApiErrorResponse> = [ 'detail', 'error', 'message' ];

export function useApiUtils() {
    const $config = useRuntimeConfig();

    const apiBaseUrlObj: Partial<URL> = (() => {
        try {
            return new $URL(`${$config.public.apiBaseURL}`);
        } catch (err) {}

        return new $URL('');
    })();

    const apiBasePath: string = (() => apiBaseUrlObj?.pathname || '')();

    function getErrorFromGenericApiErrorResponse(err: FetchError, capitalize: boolean = false): Error | void {
        let errorMsg = tryExtractObjFromError(err);
        
        if (errorMsg) {
            if (capitalize) {
                errorMsg = lo_capitalize(errorMsg);
            }

            return new Error(errorMsg);
        }
    }

    function getApiErrorStatus(err: FetchError<ApiGeneralException> | Error | unknown): string | null {
        let status = null;

        if (lo_has(err, 'data')) {
            status = (err as any)?.data?.detail || null;
        } else if (lo_isError(err)) {
            status = err?.message || null;
        }

        return status;
    }

    function getErrorObj(error: Error | unknown, altMessage = strUnknownError): Error {
        const errorMsg = (error as Error)?.message ?? altMessage;
        return Error(errorMsg);
    }

    function getNuxtErrorObj(error: NuxtError | Error | unknown, altMessage = strUnknownError): NuxtError {
        if (lo_isObject(error) && ('statusCode' in error || 'statusMessage' in error)) {
            return error as NuxtError;
        }

        const message = (error as Error)?.message ?? altMessage;
        return createError({ message });
    }

    function getPaginationQueryParams(url: string = '') {
        try {
            return new $URL(url || '').query;
        } catch (error) {
            return new $URL('').query;
        }
    }

    function customToPaginatedPayload<T>(response: CustomPagination<T>): PaginatedPayload<T> {
        const payload = response.results;

        return {
            pagination: lo_omit(response, 'results'),
            data: payload
        };
    }

    function getNotImplementedError() {
        return getErrorObj(new Error('Not implemented'));
    }

    function tryExtractObjFromError(errObj: Error | NuxtError): Nullable<string> {
        const errorObj = getDataFromError(errObj);

        if (errorObj) {
            for (const prop of genericErrorProps) {
                if (prop in errorObj) {
                    return errorObj[prop]?.toString() ?? null;
                }
            }
        }
        
        return null;
    }

    function getDataFromError(err: FetchError<unknown> | Error | unknown): Nilish<Record<string, unknown>> {
        if (lo_isObject(err) && 'data' in err) {
            return err.data as Record<string, unknown>;
        }
    }

    function isErrorOfStatusCode(errObj: Error | NuxtError, statusCode: number) {
        return ('statusCode' in errObj && errObj.statusCode === statusCode);
    }

    function getStatusTypeFromErrorObj(errObj: Error | NuxtError): HttpStatusCodeType {
        let status = HttpStatusCodeType.UNKNOWN;
        
        if ('statusCode' in errObj) {
            const code = errObj.statusCode;
            switch (true) {
                case lo_inRange(code, 100, 199):
                    return HttpStatusCodeType.INFORMATIONAL;
                case lo_inRange(code, 200, 299):
                    return HttpStatusCodeType.SUCCESSFUL;
                case lo_inRange(code, 300, 399):
                    return HttpStatusCodeType.REDIRECTION;
                case lo_inRange(code, 400, 499):
                    return HttpStatusCodeType.CLIENT;
                case lo_inRange(code, 500, 599):
                    return HttpStatusCodeType.SERVER;
            }
        }

        return status;
    }

    function getApiBaseUrl(apiName: keyof RuntimeConfig['public']['apiConfig']): Nullable<string> {
        const api = $config.public.apiConfig[apiName];

        if (api) {
            return import.meta.server ? api.basePath : api.browserBaseURL;
        }
        
        return null;
    }


    return {
        strUnknownError,
        apiBaseUrlObj,
        apiBasePath,
        getErrorFromGenericApiErrorResponse,
        getApiErrorStatus,
        getErrorObj,
        getNuxtErrorObj,
        getPaginationQueryParams,
        customToPaginatedPayload,
        tryExtractObjFromError,
        getNotImplementedError,
        isErrorOfStatusCode,
        getDataFromError,
        getStatusTypeFromErrorObj,
        getApiBaseUrl
    };
}
