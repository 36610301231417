import type { Membership } from '~/types';
import { useCustomAuth } from '~/composables';

export function useUserLite() {
    const {
        isLoggedIn,
        getSession,
        sessionObj: userObj
    } = useCustomAuth();
    const defaultBillingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultBillingAddressUid ?? null);
    const defaultShippingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultShippingAddressUid ?? null);
    const selectedMembershipUid = computed<string>(() => userObj.value?.selectedMembershipUid ?? '');

    function getCurrentMembership(): Nullable<Membership> {
        const selectedUid = userObj.value?.selectedMembershipUid;

        if (!selectedUid) {
            return null;
        }

        return userObj.value?.availableMembershipsSiteData.find((o) => o.uid === selectedUid) ?? null;
    }

    function isOrgMemberAllowed(permission?: Nilish<string>): boolean {
        const membership = getCurrentMembership();

        if (!permission) {
            return true;
        }

        if (membership?.permissions && permission in membership.permissions) {
            return membership.permissions[permission] ?? false;
        }

        return false;
    }

    function fetchUser(force = false) {
        return getSession(force);
    }

    function getIsReseller(): boolean {
        const currentMembershipObj = getCurrentMembership();

        if (currentMembershipObj) {
            return currentMembershipObj?.customer.isRetail ?? false;
        }

        return false;
    }

    return {
        userObj,
        fetchUser,
        isLoggedIn,
        getIsReseller,
        defaultBillingAddressId,
        defaultShippingAddressId,
        selectedMembershipUid,
        getCurrentMembership,
        isOrgMemberAllowed
    };
}
