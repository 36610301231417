import { isFunction as lo_isFunction } from 'lodash-es';
const now = () => performance.now();

export class Timer {
    count: number = 0;

    #running: boolean = false;
    #last: number = 0;
    #interval: number = 1000;
    #frame: number = 0;
    #fn: Function = () => {};
    #fireFn: Function = () => {};


    constructor(fn: Function, interval = 1000) {
        this.#last = now();
        this.#fn = fn;
        this.#interval = interval;
        this.#fireFn = (dt: number) => {
            if (lo_isFunction(this.#fn)) {
                this.#fn.call(self, dt);
            }
        };
    }

    start(fn?: Function): void {
        if (this.#running) {
            return;
        }

        if (lo_isFunction(fn)) {
            this.#fn = fn;
        }

        this.#running = true;
        this.#last = now();
        this.#frame = requestAnimationFrame(() => this.#tick());
    }

    stop(): void {
        this.#running = false;

        if (this.#frame !== 0) {
            cancelAnimationFrame(this.#frame);
        }

        this.#frame = 0;
    }

    destroy(): void {
        this.stop();
        this.#fn = () => {};
        this.#fireFn = () => {};
    }

    #tick() {
        this.#frame = requestAnimationFrame(() => this.#tick());
        const time = now();
        const dt = time - this.#last;

        if (dt >= this.#interval) {
            this.#last = time;
            this.count++;
            this.#fireFn(dt);
        }
    }
}