import * as v from 'valibot';
import { fieldModelErrors as fErr } from '~/constants';
import type {
    CartContactInfo,
    Cart
} from '~/types';
import { pick as lo_pick } from 'lodash-es';

export const CartContactInfoBaseModel: CartContactInfo = {
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhone: null
};

export const ContactInfoModel = v.object({
    contactFirstName: v.pipe(
        v.string(fErr.firstName),
        v.minLength(1, fErr.firstName)
    ),
    contactLastName: v.pipe(
        v.string(fErr.lastName),
        v.minLength(1, fErr.lastName)
    ),
    contactEmail: v.pipe(
        v.string(fErr.email),
        v.email(fErr.email)
    ),
    contactPhone: v.pipe(
        v.string(fErr.phone),
        v.minLength(1, fErr.phone)
    )
});

export const cartContactInfoProps: Array<keyof CartContactInfo> = Object.keys(CartContactInfoBaseModel) as Array<keyof CartContactInfo>;

export function getCartContactInfoModel(cartObj: Partial<Cart | CartContactInfo> = {}): CartContactInfo {
    const obj = lo_pick<Partial<Cart | CartContactInfo>, keyof CartContactInfo>(cartObj, cartContactInfoProps) ?? {};
    return { ...CartContactInfoBaseModel, ...obj };
}
