import { get as lo_get } from 'lodash-es';
import { getSingleParam } from '~/utils';
import { useTrackingUtils, useKookies } from '~/composables';
import type { RuntimeConfig } from 'nuxt/schema';

const shareASaleConfigObj = ref<Nullable<Record<string, string>>>(null);

export function useShareASale() {
    const useTrackingUtilsObj = useTrackingUtils();
    const { setKookie, getKookie } = useKookies();

    function init(): void {
        const route = useRoute();

        getConfig();

        if (!shareASaleConfigObj.value) {
            return;
        }

        const { queryParamName, cookieName } = shareASaleConfigObj.value;
        const cookieValue = getSingleParam(route.query, queryParamName);

        if (!cookieValue) {
            console.warn('SHARE A SALE: No code found');
            return;
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + 94670778e4);
        
        setKookie(cookieName, cookieValue, { expires }, '');
    }

    function invoke(tracking: string, amount: number) {
        getConfig();

        if (!shareASaleConfigObj.value) {
            return;
        }

        const { merchantID, cookieName } = shareASaleConfigObj.value;
        const sscid = getKookie(cookieName, '');

        if (!sscid) {
            console.warn('SHARE A SALE: No cookie found');
            return;
        }

        const pixel = makePixelUrl({ tracking, amount: `${amount}`, sscid, merchantID });
        useTrackingUtilsObj.appendTrackingPixel(pixel);
    }

    function getConfig(): void {
        shareASaleConfigObj.value = shareASaleConfigObj.value ||
            (
                lo_get(useRuntimeConfig(), 'public.environment.tracking.shareASale') as Record<string, string> ?? null
            );

        if (!shareASaleConfigObj.value || !shareASaleConfigObj.value.enabled) {
            console.warn('Share A Sale is either not enabled or not configured');
            return;
        }
    }

    function makePixelUrl(params: Record<string, string>): string {
        const { pixelUrlBase } = shareASaleConfigObj.value;

        try {
            const url = new URL(pixelUrlBase);
            url.search = (new URLSearchParams(params)).toString();
            return url.toString();
        } catch (error) {
            return pixelUrlBase;
        }
    }

    return {
        init,
        invoke
    };
}

